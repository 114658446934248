import styled, { css } from 'styled-components';

import { ReactComponent as ChevronSVG } from 'assets/images/icons/chevron.svg';
import Box from '../Box';

export const PaginationContainer = styled(Box)(
  () => css`
    background: #FFF;
    border: 2px;
    box-shadow: 0 0 4px 1px rgba(128, 141, 150, 0.15);
    justify-content: center;
    margin: 0px 24px 24px;
    padding: 24px 0px;

    button {
      background-color: rgb(255, 255, 255);
      border: 0;
      cursor: pointer;
      padding: 0;

      &:hover {
        div {
          background-color: rgb(235, 250, 255) !important;
        }
      }

      & > div {
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: 8px;
        margin-left: 0px;
        min-height: 40px;
        min-width: 40px;
      }

      & > div > span {
        color: rgb(143, 176, 212);
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 24px !important;
      }

      &.active-button {
        &:hover > div {
          background: transparent !important
          cursor: not-allowed;
        }

        & > div > span {
          color: rgb(30, 63, 107) !important;
        }
      }

      &.inactive-prev-button:hover, &.inactive-next-button:hover {
        & > div {
          background: transparent !important
          cursor: not-allowed;
        }
      }
    }
  `,
);

export const ForwardChevron = styled(ChevronSVG)(
  () => css`
    width: 20px;
    height: 16px;
    user-select: none;

    path {
      fill: #1e3f6b;
    }

    &.inactive-prev-button,
    &.inactive-next-button {
      path {
        fill: #bed2e8;
      }
    }
  `,
);

export const BackChevron = styled(ForwardChevron)`
  transform: rotate(180deg);
`;
