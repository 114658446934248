import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Text from '../Text';
import usePagination, { DOTS } from './usePagination';
import {
  PaginationContainer,
  ForwardChevron,
  BackChevron,
} from './Pagination.style';

export const dataTestid = 'basic-pagination-id';
export const dataTabTestid = 'basic-pagination-tab-id';

const Pagination = ({
  currentPage,
  maxLength,
  onGoingToPage,
  siblingLength,
  containerClassname,
  bgContainer,
  ...props
}) => {
  const paginationRange = usePagination({
    currentPage,
    maxLength,
    siblingCount: siblingLength,
  });
  const onClickToNavigate = page => {
    return onGoingToPage(page);
  };

  return (
    <PaginationContainer
      display="flex"
      flexDirection="row"
      alignItems="center"
      className={containerClassname}
      data-testid={dataTestid}
      bgColor={bgContainer}
    >
      <button
        id="prev-button"
        data-testid={`${dataTabTestid}-chevron-left`}
        disabled={currentPage <= 1}
        onClick={() => onClickToNavigate(currentPage - 1)}
        className={
          currentPage <= 1 ? 'inactive-prev-button' : 'active-prev-button'
        }
      >
        <Box
          p="2px 4px"
          cursor={currentPage <= 1 ? 'auto' : 'pointer'}
          {...props}
          _hover={{ backgroundColor: '#EBFAFF' }}
        >
          <BackChevron className={currentPage <= 1 && 'inactive-prev-button'} />
        </Box>
      </button>
      {paginationRange?.map((pageNumber, index) => {
        const key = `${dataTabTestid}-${pageNumber}`;

        const pageNumberBefore = paginationRange[index - 1];
        const isAfterDots =
          typeof pageNumberBefore === 'string' &&
          pageNumberBefore.includes(DOTS);

        if (typeof pageNumber === 'string' && pageNumber.includes(DOTS)) {
          return (
            <Box
              id="box-ellipsis"
              key={pageNumber}
              p="2px 10px"
              {...props}
              mr="8px"
            >
              <Text fontSize="md" color="gray.300">
                &#8230;
              </Text>
            </Box>
          );
        }
        const page = pageNumber;
        return (
          <button
            key={key}
            data-testid={key}
            disabled={page === currentPage}
            onClick={() => onClickToNavigate(page)}
            className={
              pageNumber === currentPage ? 'active-button' : 'inactive-button'
            }
          >
            <Box
              key={key}
              p="2px 10px"
              {...props}
              {...(isAfterDots && { mx: 0 })}
            >
              <Text fontSize="14px">{pageNumber}</Text>
            </Box>
          </button>
        );
      })}
      <button
        id="next-button"
        data-testid={`${dataTabTestid}-chevron-right`}
        disabled={currentPage >= maxLength}
        onClick={() => onClickToNavigate(currentPage + 1)}
        className={
          currentPage >= maxLength
            ? 'inactive-next-button'
            : 'active-next-button'
        }
      >
        <Box
          p="2px 4px"
          cursor={currentPage >= maxLength ? 'auto' : 'pointer'}
          {...props}
        >
          <ForwardChevron
            className={currentPage >= maxLength && 'inactive-next-button'}
          />
        </Box>
      </button>
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  onGoingToPage: PropTypes.func.isRequired,
  siblingLength: PropTypes.number,
  containerClassname: PropTypes.string,
  bgContainer: PropTypes.string,
};

export default Pagination;
